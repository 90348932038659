import { getUserInfoApi } from '@/api/common'

export default {
  namespaced: true,
  state: {
    userInfo: null
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.userInfo = info || null
    }
  },
  actions: {
    async getUserInfo ({ state, commit }) {
      try {
        const userInfo = await getUserInfoApi()
        commit('SET_INFO', userInfo)
        // 麒麟阁
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}
