<template>
  <div class="layout-default">
    <div class="layout-content">
      <keep-alive :include="keepAliveList">
        <router-view></router-view>
      </keep-alive>
    </div>
    <van-tabbar class="layout-tabbar" v-model="active" route>
      <van-tabbar-item icon="chat" replace to="/chat">对话</van-tabbar-item>
      <van-tabbar-item icon="user" replace to="/my">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  data () {
    return {
      active: 0,
      keepAliveList: [
        'ChatPage'
      ]
    }
  }

}
</script>

<style lang="scss">
.layout-default{
  display: flex;
  height: 100%;
  flex-direction: column;
  .layout-tabbar{
    height: 60px;
    position: relative;
  }
  .layout-content{
    flex: 1;
    height: 0;
  }
}

</style>
