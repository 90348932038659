import Layout from '@/layout/index.vue'

export const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'chat',
    children: [
      {
        path: 'chat',
        name: 'chat',
        component: () => import('@/views/chat/index.vue')
      },
      {
        path: 'my',
        name: 'my',
        component: () => import(/* webpackChunkName: "my" */ '@/views/my/index.vue')
      }
    ]
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue')
  }

]
