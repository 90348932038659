import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './route'
import store from '@/store'
import { handleLogin } from '@/utils'
import { isWhiteList } from '@/router/permission'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async (to, from, next) => {
  console.log(to)
  if (to.query.code && to.query.state === 'qlinyun') {
    const res = await handleLogin(to.query.code)
    if (res === 'goRegister') {
      return
    }
    console.log('isLogin')
    window.location.href = '/chat'
  }

  console.log(isWhiteList(to))
  if (isWhiteList(to)) {
    next()
  } else {
    // 未登录,先登录
    try {
      if (!store.state.user.userInfo) {
        console.log('startGetUserInfo')
        await store.dispatch('user/getUserInfo')
        next()
      } else {
        next()
      }
    } catch (err) {
    }
  }
})

export default router
