
export const aiUrl = window.location.href.split('com')[0] + 'com/'
export const APPID = 'wx2e9934570323538b'

// 触发微信登录，获取code，然后会重定向回来
// 接下来在app.vue中，拿到code，然后向后端交互，实现登录
export function startWechatAuthorize () {
  const redirectUrl = encodeURI(aiUrl)
  const scope = 'snsapi_userinfo'
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=qlinyun#wechat_redirect`
  window.location.href = url
}

// 对麒麟云API进行授权
export async function apiAuthorize () {
  const codeUrl = await fetch('/api/oauth2/authorization/qlinyun-official-ai', {
    credentials: 'include'
  })
  const codeUrlJson = await codeUrl.json()
  console.log(codeUrlJson)
  // 返回了携带code的url，从url中获取code
  // 接下来调用code换JSESSONID
  const apiCode = getUrlParam(codeUrlJson.data, 'code')
  const apiState = getUrlParam(codeUrlJson.data, 'state')
  // 使用code & state获取cookie
  await fetch(`/api/login/oauth2/code/qlinyun-official-ai?code=${apiCode}&state=${apiState}`, {
    credentials: 'include'
  })
}

// 登录
export async function handleLogin (code) {
  const res = await fetch(ssoHost + `/sso/login/oauth2/wechat-official-qlinyun-ai?code=${code}`, {
    credentials: 'include'
  })
  const jsonRes = await res.json()
  console.log(jsonRes)
  // 用户未绑定微信或者未注册为麒麟云用户
  if (jsonRes.status === 'A0102') {
    window.location.href = `/register?wxcode=${code}&appid=${APPID}`
    return 'goRegister'
  } else {
    // 认证中心已登录，开始进行麒麟云API的授权
    await apiAuthorize()
  }
}

// 获取 URL 内参数
export function getUrlParam (url, paraName) {
  const arrObj = url.split('?')
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')
      // eslint-disable-next-line eqeqeq
      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}

export const ssoHost = 'https://sso.qlinyun.com'
