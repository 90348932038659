import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Icon, Button, Tabbar, TabbarItem, NavBar, Form, Field, Row, Col, Image } from 'vant'
import VueTypedJs from 'vue-typed-js'
import store from './store'

import './style/reset.css'

import 'vant/lib/index.css'

Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)

Vue.use(VueTypedJs)

Vue.config.productionTip = false
Vue.use(Icon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
