import axios from 'axios'
import { startWechatAuthorize } from '@/utils'

export class RequestError extends Error {
  constructor (message) {
    super()
    this.message = message
    this.name = this.constructor.name
  }
}

// 在类上定义默认值
axios.defaults.headers['Content-Type'] = 'application/json'
// 接口请求是否绕过300ms内不可重复请求。开启时，请确认代码的可用性。
axios.defaults.unique = false

// 创建axios实例
const service = axios.create({
  baseURL: '/api',
  // 请求超时时间
  timeout: 10 * 60 * 1000
})

// request拦截器
service.interceptors.request.use((config) => {
  // 如果当前网络有问题，直接报错
  if (!window.navigator.onLine) {
    throw new RequestError('请检查您的网络情况')
  }
  config.headers = {
    ...config.headers,
    // 防止接口请求缓存。https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Headers/Cache-Control
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
  }
  return config
}, (error) => {
  throw new RequestError(error)
})

// response拦截器
service.interceptors.response.use(async (response) => {
  // 数据处理
  const { status, data, message } = response.data
  if (status === 'success' || status === '00000') {
    return data
  }
  return Promise.reject(new RequestError(message))
}, (error) => {
  const { response } = error
  if (error instanceof RequestError) {
    // 自定义错误类直接抛出
    throw error
  } else {
    if (response.status === 401) {
      startWechatAuthorize()
      return
    }
    // 处理HTTP 错误 如404
    throw new RequestError(error.message)
  }
})

export default service
