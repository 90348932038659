
// 白名单页面无需登录，无需权限
export const WHITE_LIST = [
  {
    name: '注册',
    path: '/register'
  }
]

// 是否是白名单页面。
export function isWhiteList (to) {
  return WHITE_LIST.filter(i => i.path === to.path).length
}
